<template>
    <div v-if="transactionData">
        <PageSection>
            <div class="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <h1 class="h4 mb-0">{{transactionData.type.description}} {{ transactionData.id }} - {{ transactionData.patient_name }}</h1>
                </div>
                <div>
                    <span v-if="transactionData && (transactionData.type.id == 1 || transactionData.type.id == 4)" class="btn btn-sm btn-secondary" @click="openTransactionPdf">Print</span>
                    <span v-if="transactionData && transactionData.inv_order_id" class="btn btn-sm btn-secondary ms-1" @click="openPatientStatementPdf">Patient Statement</span>
                </div>
            </div>
            <div v-if="transactionData && transactionData.inv_order_id"><strong>Order:</strong> <router-link :to="{ name: 'Order', params: { id: transactionData.inv_order_id }}">#{{transactionData.inv_order_id}}</router-link></div>
            <div><strong>Date:</strong> {{$filters.formatDate(transactionData.listed_date)}}</div>
            <div><strong>Total:</strong> &pound;{{transactionData.total}}</div>
        </PageSection>
        <PageSection isLastSection="true">
            <PageSectionHeading v-if="transactionData && transactionData.items.length > 0" class="h5 mb-2">Items</PageSectionHeading>
            <table v-if="transactionData && transactionData.items.length > 0" class="product-table mb-4">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th class="column-number">Qty</th>
                        <th class="column-number">Price</th>
                        <th class="column-number">Total</th>
                    </tr>
                </thead>
                <tbody v-for="(transactionItem, transactionItemIndex) of transactionData.items" v-bind:key="transactionItemIndex" class="mb-1">
                    <tr>
                        <td>
                            <div>
                                {{transactionItem.product_name}}
                            </div>
                        </td>
                        <td class="column-number ps-3">{{transactionItem.unit}}</td>
                        <td class="column-number ps-3">£{{transactionItem.price}}</td>
                        <td class="column-number ps-3">£{{transactionItem.total}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div class="col">
                    <div class="h5 mb-0">
                        Total
                    </div>
                </div>
                <div class="col d-flex justify-content-end">
                    <table class="product-table product-table-collapsed">
                        <tr>
                            <td class="column-number ps-4"><strong>£{{transactionData.total}}</strong></td>
                        </tr>
                    </table>
                </div>
            </div>
        </PageSection>
    </div>
</template>

<style scoped lang="scss">
    .product-table
    {
        border-collapse: collapse;
        width: 100%;
        line-height: 1em;

        &.product-table-collapsed
        {
            width: auto;
        }

        td,
        th
        {
            vertical-align: top;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
        }

        .column-number
        {
            width: 15%;
            white-space: nowrap;
            text-align: right;
        }
    }
</style>

<script>
    import api from "@/services/api";
    import base64 from "@/services/base64";
    import PageSection from '@/components/PageSection.vue';
    import PageSectionHeading from '@/components/PageSectionHeading.vue';

    export default {
        props: {
            account_id: {
                required: true
            },
            id: {
                required: true
            }

        },
        data() {
            return {
                transactionData: null,
            };
        },
        components: {            
            PageSection,
            PageSectionHeading,
        },
        mounted () {
            this.fetchTransaction();
        },
        methods: {
            fetchTransaction: function() {                
                api
                .get('/api/v1/accounts/' + this.account_id + '/transactions/' + this.id)
                .then((response) => {
                    this.transactionData = response.data.data;
                })
                .catch(() => {
                    console.log('Catch transaction error');
                });
            },
            openTransactionPdf: function() {
                var pdfWindow = window.open();
                pdfWindow.document.title = "Loading...";

                api
                .get('/api/v1/accounts/' + this.account_id + '/transactions/' + this.id + '/pdf')
                .then((response) => {
                    var blob = base64.toBlob(response.data, 'application/pdf');
                    pdfWindow.location = URL.createObjectURL(blob);
                })
                .catch((error) => {
                    pdfWindow.close();
                    console.log('Catch transaction pdf error', error);
                    alert('There was an error downloading this item, please try again later.');
                });
            },
            openPatientStatementPdf: function() {
                var pdfWindow = window.open();
                pdfWindow.document.title = "Loading...";

                api
                .get('/api/v1/accounts/' + this.account_id + '/transactions/' + this.id + '/patient-statement')
                .then((response) => {
                    var blob = base64.toBlob(response.data, 'application/pdf');
                    pdfWindow.location = URL.createObjectURL(blob);
                })
                .catch((error) => {
                    pdfWindow.close();
                    console.log('Catch patient statement pdf error', error);
                    alert('There was an error downloading this patient statement, please try again later.');
                });
            },
        },
    }

</script>
