<template>
        <div class="mb-3">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <router-link :to="{ name: 'Reports' }" class="nav-link">Reports</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'PatientStatements' }" class="nav-link">Patient Statements</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'Exports' }" class="nav-link active">Exports</router-link>
                </li>
            </ul>
            <div class="bg-white pt-4">
                <table v-if="exportsData" class="exports-table table">
                    <thead>
                        <tr>
                            <th class="column-shrink">Date Created</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody v-for="(bulkExport, bulkExportIndex) of exportsData.data" v-bind:key="bulkExportIndex" class="mb-1">
                        <tr>
                            <td>
                                {{ bulkExport.datetime_created }}
                            </td>
                            <td>
                                {{ bulkExport.start_date }}
                            </td>
                            <td>
                                {{ bulkExport.end_date }}
                            </td>
                            <td>
                                {{ bulkExport.status }}
                            </td>
                            <td>
                                <a :href=bulkExport.download_url class="btn btn-sm btn-primary" target="_blank" v-if="bulkExport.status=='Completed'">
                                    Download
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="exportsData && !exportsData.data.length">
                    No Bulk Exports Found
                </div>
                <Pagination
                    v-if="exportsData && exportsData.data.length"
                    v-bind:currentPage="currentPage"
                    v-bind:lastPage="lastPage"
                    viewComponent="Exports"
                />
            </div>
        </div>

</template>

<style>
table {
    font-size: 1.2rem;
}
</style>

<script>
    import api from "@/services/api";
    import Pagination from '@/components/Pagination.vue';

    export default {
        data() {
            return {
                exportsData: null,
            }
        },
        components: {
            Pagination,
        },
        methods: {
            fetchExports: function() {
                var params = {
                    page: this.currentPage,
                };
                
                api
                .get('/api/v1/exports', {
                    params: params
                })
                .then((response) => {
                    this.exportsData = response.data;

                    console.log(this.exportsData.meta.total);
                })
                .catch(() => {
                    console.log('Catch patient statements error');
                });
            },
        },
        mounted () {
            this.fetchExports();
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.patientStatementsData !== null)
                {
                    return parseInt(this.exportsData.meta.last_page);
                }

                return null;
            },
        },
        watch: {
            currentPage() {
                this.fetchExports();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchExports();
            }
        },
    }

</script>