<template>
     <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
            <h1 class="h4 mb-0">Reports</h1>
        </div>
    </div>
    <div class="row">
        <SubsectionLink :to="{ name: 'Invoices' }" heading="Invoices" description="Download your invoices for a given date range" />
        <SubsectionLink :to="{ name: 'PatientStatements' }" heading="Patient Statements" description="Download your patient statements for a given date range" />
    </div>
</template>

<style scoped lang="scss">
.advert
    {
        line-height: 1.1em;
        background: $swift-yellow;
        padding: 1em;
        font-size: 2.5em;

        @include media-breakpoint-up(lg)
        {
            font-size: 3em;
        }
    }
.quick-link
    {
        text-decoration: none;
        display: flex;

        .text
        {
            flex-grow: 1;
            margin-right: $spacer;
        }

        .text-description
        {
            line-height: 1.3em;
        }

        .arrow
        {
            img
            {
                width: 30px;
                margin-top: 10px;
            }            
        }

        &:hover
        {
            .icon
            {
                background: $swift-light-grey;
                transition: 0.5s;
            }
        }
    }
</style>

<script>
    import SubsectionLink from '@/components/SubsectionLink.vue';

    export default {
        mounted () {
                       
        },
        components: {
            SubsectionLink,
        }
    }

</script>