<template>
    <div>
        <h1 class="h4">Invoices</h1>
        <form class="bg-light p-3" novalidate @submit.prevent="submitFilter">
            <div class="row">
                <div class="col-md-3">
                    <DateInput
                        label="From date"
                        v-model="date_from"
                        :v="v$.date_from"
                    />
                </div>
                <div class="col-md-3">
                    <DateInput
                        label="To date"
                        v-model="date_to"
                        :v="v$.date_to"
                    />
                </div>
                <div class="col-md-5 col-lg-4 d-flex align-items-end">
                    <span v-if="filterEnabled" class="btn btn-primary me-1" @click="fetchPatientStatementsExports">Filter</span>
                    <span  id="export_all_button" class="btn btn-primary" @click="createExportBulk">
                        Generate Export                  
                    </span>
                </div>
            </div>
        </form>
        <Warning v-if="warning" v-bind:warning="warning" />
        <br />
        <div class="bg-white pt-0">
            <table v-if="exportsData" class="exports-table table">
                <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th class="column-shrink">Date Created</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody v-for="(bulkExport, bulkExportIndex) of exportsData.data" v-bind:key="bulkExportIndex" class="mb-1">
                    <tr>
                        <td>
                            {{ bulkExport.start_date }}
                        </td>
                        <td>
                            {{ bulkExport.end_date }}
                        </td>
                        <td>
                            {{ bulkExport.datetime_created }}
                        </td>
                        <td  v-if="bulkExport.status!='Completed'">
                            {{ bulkExport.status }}
                        </td>
                        <td v-if="bulkExport.status=='Completed'">
                            <a :href=bulkExport.download_url class="btn btn-sm btn-primary" target="_blank">
                                Download
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="exportsData && !exportsData.data.length">
                No invoice exports found
            </div>
            <Pagination
                v-if="exportsData && exportsData.data.length"
                v-bind:currentPage="currentPage"
                v-bind:lastPage="lastPage"
                viewComponent="Invoices"
            />
        </div>
    </div>
</template>

<script>
    import api from "@/services/api";
    import useVuelidate from '@vuelidate/core'
    import Pagination from '@/components/Pagination.vue';
    import DateInput from '@/components/DateInput.vue';
    import Warning from '@/components/Warning.vue';

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        data() {
            return {
                exportsData: null,
                date_from: '',
                date_to: '',
                warning: null,
                refresh: null,
                filterEnabled: false,
                exportType: 1,
            }
        },
        components: {
            Pagination,
            DateInput,
            Warning
        },
        validations () {
            return {
                date_to: { },
                date_from: { }
            }
        },
        methods: {
            fetchInvoicesExports: function() {
                if(!this.$store.state.currentPracticeId)
                {
                    this.exportsData = null;
                    return;
                }
                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                    export_type: this.exportType,
                    page: this.currentPage,
                };
                
                api
                .get('/api/v1/exports', {
                    params: params
                })
                .then((response) => {
                    this.exportsData = response.data;
                })
                .catch(() => {
                    console.log('Catch invoices error');
                });
            },
            createExportBulk: function() {
                this.warning = null;
                
                var params = {
                    practice_id: this.$store.state.currentPracticeId,
                };

                if(this.date_from.trim().length)
                {
                    params.from = this.date_from;
                } else {
                    this.warning = 'Please set a from date';
                    return;
                }

                if(this.date_to.trim().length)
                {
                    params.to = this.date_to;
                } else {
                    this.warning = 'Please set a to date';
                    return;
                }

                params.export_type = this.exportType;

                document.getElementById('export_all_button').removeAttribute('onclick');

                api
                .post('/api/v1/accounts/createBulkExport', {
                    ...params,
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data != 'Done') {
                        this.warning = response.data;
                    } else {
                        this.warning = null;
                    }
                    this.fetchInvoicesExports();
                    clearInterval(this.refresh);
                    this.refresh = window.setInterval(() => {
                        this.fetchInvoicesExports();
                    }, 10000);
                })
                .catch(() => {
                    console.log('Catch transaction error');
                });
            }
        },
        mounted () {
            this.fetchInvoicesExports();
        },
        computed: {
            currentPage () {
                var pageParam = this.$route.query.page;
                return typeof pageParam === 'undefined' ? 1 : parseInt(pageParam);
            },
            lastPage () {
                if(this.exportsData !== null)
                {
                    return parseInt(this.exportsData.meta.last_page);
                }

                return null;
            },
        },
        watch: {
            currentPage() {
                this.fetchInvoicesExports();
            },
            '$store.state.currentPracticeId': function() {
                this.fetchInvoicesExports();
            }
        },
        beforeUnmount() {
            clearInterval(this.refresh);
        }
    }

</script>

